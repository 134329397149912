@import '../custom';

@mixin alert-variant($background, $border, $color) {
  color: $color;
  background: $background
    linear-gradient(180deg, mix($body-bg, $background, 15%), $background)
    repeat-x;
  border-color: $border;

  hr {
    border-top-color: darken($border, 5%);
  }

  .alert-link {
    color: darken($color, 10%);
  }
}
