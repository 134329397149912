@import './brand';
@import './utilities';

// stylelint-disable no-descending-specificity

body {
  pointer-events: none; // allow click through
  * {
    pointer-events: all; // allow click event
  }
}

.ue-app-container {
  background-color: $ue-white;
  margin-top: $ue-top-ad-height-mobile + $ue-header-height;
  @include media-breakpoint-up(md) {
    margin-top: $ue-top-ad-height-desktop + $ue-header-height;
  }
}

// REM conversion
$font-size-reset: 62.5%; // 62.5% so that 1rem = 10px
html {
  font-size: $font-size-reset;
}

// Screenreaders
.sr-only {
  @include visually-hidden();
}

.sr-only-focusable {
  @include visually-hidden-focusable();
}

// Global print utilities
@media print {
  // will still print if [data-print]=""
  [data-print='none'] {
    display: none;
    * {
      // hide children too, they think they're free
      display: none;
    }
  }
  [data-print='inline'] {
    display: inline;
  }
  [data-print='block'] {
    display: block;
  }
}

a,
button {
  &:focus {
    // stylelint-disable plugin/no-unsupported-browser-features
    outline-style: dotted;
    outline-width: 1px;
    outline-color: $ue-dark-gray;
    outline-offset: $spacer * 0.5;
    // stylelint-enable plugin/no-unsupported-browser-features
  }
}

// Slick theme overrrides
// stylelint-disable declaration-no-important
.slick-prev,
.slick-next {
  top: -20px !important;
  z-index: $zindex-sticky;
  &:before {
    color: $ue-black !important;
  }
}

.slick-prev {
  left: 0 !important;
}

.slick-next {
  right: 0 !important;
}

.slick-dots {
  // 'unset' is okay to use here, if unsupported the
  // slider navigation will still function as intended.
  // stylelint-disable-next-line plugin/no-unsupported-browser-features
  bottom: unset !important;
  top: -45px;
}
// stylelint-enable declaration-no-important

// Bootstrap carry overs
.close {
  float: right;
  font-size: $close-font-size;
  font-weight: $close-font-weight;
  line-height: 1;
  color: $close-color;
  text-shadow: $close-text-shadow;
  opacity: 0.5;

  // Override <a>'s hover style
  &:hover {
    color: $close-color;
    text-decoration: none;
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:focus {
      opacity: 0.75;
    }
  }
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

// stylelint-disable-next-line selector-no-qualifying-type
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

// Future-proof disabling of clicks on `<a>` elements

// stylelint-disable-next-line selector-no-qualifying-type
a.close.disabled {
  pointer-events: none;
}

.alert {
  position: relative;
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border: $alert-border-width solid transparent;
  border-radius: $alert-border-radius;
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: $close-font-size + $alert-padding-x * 2;

  // Adjust close link position
  .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: $alert-padding-y $alert-padding-x;
    color: inherit;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include alert-variant(
      theme-color-level($color, $alert-bg-level),
      theme-color-level($color, $alert-border-level),
      theme-color-level($color, $alert-color-level)
    );
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s, `<ul>`s or `<ol>`s.

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: $nav-link-padding-y $nav-link-padding-x;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  // Disabled state lightens text
  &.disabled {
    color: $nav-link-disabled-color;
    pointer-events: none;
    cursor: default;
  }
}

//
// Tabs
//

.nav-tabs {
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

  .nav-item {
    margin-bottom: -$nav-tabs-border-width;
  }

  .nav-link {
    border: $nav-tabs-border-width solid transparent;
    border-top-left-radius: $nav-tabs-border-radius;
    border-top-right-radius: $nav-tabs-border-radius;

    &:hover,
    &:focus {
      border-color: $nav-tabs-link-hover-border-color;
    }

    &.disabled {
      color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-tabs-link-active-color;
    background-color: $nav-tabs-link-active-bg;
    border-color: $nav-tabs-link-active-border-color;
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -$nav-tabs-border-width;
    // Remove the top rounded corners here since there is a hard edge above the menu
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

//
// Pills
//

.nav-pills {
  .nav-link {
    border-radius: $nav-pills-border-radius;
  }

  .nav-link.active,
  .show > .nav-link {
    color: $nav-pills-link-active-color;
    background-color: $nav-pills-link-active-bg;
  }
}

//
// Justified variants
//

.nav-fill {
  > .nav-link,
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  > .nav-link,
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}

// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}
