@import '../../../scss/brand';

// TODO: Import all styles being hydrated from the
// primaryLinkStyles prop and refactor out !important

.primary {
  @each $category, $color in $category-colors {
    &--#{$category}:hover,
    &--#{$category}-active {
      // stylelint-disable declaration-no-important
      color: $color !important;
      // stylelint-enable declaration-no-important
    }
  }
  &_active {
    // stylelint-disable declaration-no-important
    color: $ue-black !important;
    // stylelint-enable declaration-no-important
  }
}
