// https://css-tricks.com/overriding-default-button-styles/
.button {
  padding: 0;
  border: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
  background-color: transparent;
  [type='button'],
  [type='reset'],
  [type='submit'] {
    // stylelint-disable plugin/no-unsupported-browser-features
    // stylelint-disable property-no-vendor-prefix
    -webkit-appearance: button;
  }
}
