@import '../../scss/utilities';

$animation-timer: 500;

:export {
  var_animationTimer: $animation-timer;
  var_adHeightDesktop: $ue-top-ad-height-desktop;
  var_adHeightMobile: $ue-top-ad-height-mobile;
}

.adContainer {
  z-index: $zindex-popover;
  position: fixed;
  width: 100%;
  top: 0;
  &_animatePosition {
    top: -$ue-top-ad-height-mobile;
    transition: top #{$animation-timer}ms linear;
    @include media-breakpoint-up(md) {
      top: -$ue-top-ad-height-desktop;
    }
  }
}

.headerContainer {
  z-index: $zindex-popover;
  position: fixed;
  width: 100%;
  top: $ue-top-ad-height-mobile;
  @include media-breakpoint-up(md) {
    top: $ue-top-ad-height-desktop;
  }
  &_animatePosition {
    top: 0;
    transition: top #{$animation-timer}ms linear;
  }
}

.header {
  @include make-container();
  background-color: $white;
  border-bottom: 1px solid $ue-light-gray;
}

.header__container {
  @include make-container();
  @include make-container-max-widths();
}

.header__content {
  @include make-row();
  height: $ue-header-height;
  position: relative;
  @include media-breakpoint-up(lg) {
    display: none; // react-responsive fallback
  }
  &_desktop {
    display: none; // react-responsive fallback
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }
}

.header__main {
  @include make-col-ready();
  width: auto;
  max-width: 100%;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__side {
  @include make-col-ready();
  @include make-col-auto();
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__logo {
  color: $ue-black;
  font-size: $ue-font-size-7;
  @include media-breakpoint-down(md) {
    // stylelint-disable plugin/no-unsupported-browser-features
    // cheap logo centering on mobile to account for burger space
    margin-left: -4.7rem; // fallback
    margin-left: calc(-#{$ue-font-size-7} - #{$grid-gutter-width});
    // stylelint-enable plugin/no-unsupported-browser-features
  }
  &_open {
    margin-left: 0;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    color: $ue-black;
  }
}

.header__link {
  display: block;
  padding: 0.5rem 0.9rem;
  font-size: $ue-font-size-10;
  font-weight: 700;
  color: $ue-medium-gray;
  text-transform: uppercase;
  &:hover,
  &:focus,
  &_active {
    color: $ue-black;
    text-decoration: none;
  }
}
