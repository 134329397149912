@import '../../../scss/utilities';

.top {
  height: $ue-top-ad-height-mobile;
  width: 100%;
  padding: $grid-gutter-width*0.5;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: $ue-dark-gray;
  color: $gray-500;
  @include media-breakpoint-up(md) {
    height: $ue-top-ad-height-desktop;
  }
}
