@import '../../scss/utilities';

$container-width: 300px;

.mobile {
  width: $container-width;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -$container-width;
  background-color: $ue-white;
  z-index: $zindex-popover;
  transition: left 0.25s ease-in-out;
  overflow-y: scroll;
  @include media-breakpoint-up(sm) {
    height: auto;
    position: absolute;
  }
  &_open {
    width: 100%;
    left: 0;
    box-shadow: 0px 0px 20px $ue-shadow;
    @include media-breakpoint-up(sm) {
      width: $container-width;
    }
  }
}

.mobile__nav {
  padding: $grid-gutter-width*0.5;
  position: relative;
}

.mobile__open {
  font-size: $ue-font-size-9;
}

.mobile__close {
  position: absolute;
  top: $grid-gutter-width*0.5;
  right: $grid-gutter-width*0.5;
  font-size: $ue-font-size-6;
}

.mobile__logo {
  margin: $grid-gutter-width*0.5 0;
}

.mobile__links {
  a {
    display: block;
    font-family: $ue-subheader-font;
    color: $ue-black;
    &:hover,
    &:focus {
      text-decoration: none;
      color: $ue-medium-gray;
    }
  }
  &_primary {
    margin-bottom: $grid-gutter-width;
    // stylelint-disable-next-line no-descending-specificity
    a {
      padding: 0.8rem 0;
      border-bottom: $ue-dashed-border;
      font-size: $ue-font-size-9;
    }
  }
  &_auxiliary {
    margin-bottom: $grid-gutter-width * 2;
    // stylelint-disable-next-line no-descending-specificity
    a {
      margin-bottom: 0.4rem;
      font-size: $ue-font-size-10;
    }
  }
}

.mobile__copyright {
  text-align: center;
  font-size: $ue-font-size-10;
  // stylelint-disable-next-line no-descending-specificity
  a {
    color: $ue-black;
    &:hover,
    &:focus {
      color: $ue-medium-gray;
    }
  }
}
