@import '../../scss/utilities';

$link-color: $ue-white;

.footer {
  background-color: $ue-dark-gray;
  font-family: $ue-subheader-font;
}

.footer__container {
  @include make-container();
  @include make-container-max-widths();
  padding-top: $grid-gutter-width * 2;
  padding-bottom: $grid-gutter-width * 2;
}

.footer__wrapper {
  @include make-row();
}

.footer__content {
  @include make-col-ready();
  @include make-col(12);
}

.footer__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // add some spacing, but trim it slightly to center content
  // stylelint-disable plugin/no-unsupported-browser-features
  // IE doesn't like calc for a lot of things, but it should be fine here and a fallback is provided
  margin-bottom: $grid-gutter-width * 2;
  margin-bottom: calc(#{$grid-gutter-width * 2} - #{$nav-link-padding-y});
  // stylelint-enable plugin/no-unsupported-browser-features
}

.footer__navItem {
  flex: 1 1 100%;
  align-self: center;
  color: $link-color;
  &:hover {
    color: $link-color;
  }
  &:focus {
    // stylelint-disable plugin/no-unsupported-browser-features
    outline-style: dotted;
    outline-width: 1px;
    outline-color: $ue-light-gray;
    outline-offset: $spacer*0.5;
    // stylelint-enable plugin/no-unsupported-browser-features
  }
  &_logo {
    text-align: center;
    font-size: $ue-font-size-6;
    @include media-breakpoint-up(lg) {
      flex: 0 1 auto;
      text-align: left;
    }
  }
  &_link {
    font-size: $ue-font-size-8;
    border-bottom: 1px dashed $ue-medium-gray;
    @include media-breakpoint-only(sm) {
      flex: 0 1 25%;
      text-align: center;
    }
    @include media-breakpoint-up(sm) {
      border-bottom: 1px dashed transparent;
    }
    @include media-breakpoint-up(md) {
      flex: 0 1 auto;
    }
  }
}

.footer__auxiliary {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.footer__auxiliaryContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: $grid-gutter-width*0.5;
}

.footer__auxiliaryItem {
  color: $ue-white;
  font-size: $ue-font-size-9;
  &:hover {
    color: $link-color;
  }
  &:focus {
    // stylelint-disable plugin/no-unsupported-browser-features
    outline-style: dotted;
    outline-width: 1px;
    outline-color: $ue-light-gray;
    outline-offset: $spacer*0.5;
    // stylelint-enable plugin/no-unsupported-browser-features
  }
}

.footer__copyright {
  flex: 1 0 100%;
  text-align: center;
  color: $ue-white;
  font-size: $ue-font-size-10;
  &_link:hover {
    color: $ue-white;
  }
}
